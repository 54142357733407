import {API, PageModel, Resource} from '@coveo/platform-client';
import {BaseFacetFieldConfigurationModel, FacetFieldConfigurationOptions} from '../../interfaces';

export class FacetFieldConfigurations extends Resource {
    static baseUrl = `/rest/organizations/${API.orgPlaceholder}/commerce/v2/configurations/facets/fields`;

    list<T extends BaseFacetFieldConfigurationModel>(options: FacetFieldConfigurationOptions): Promise<PageModel<T>> {
        return this.api.get<PageModel<T>>(this.buildPath(FacetFieldConfigurations.baseUrl, options));
    }

    get<T extends BaseFacetFieldConfigurationModel>(id: string): Promise<T> {
        return this.api.get<T>(`${FacetFieldConfigurations.baseUrl}/${id}`);
    }

    create<T extends BaseFacetFieldConfigurationModel>(model: T): Promise<T> {
        return this.api.post<T>(FacetFieldConfigurations.baseUrl, model);
    }

    update<T extends BaseFacetFieldConfigurationModel>(id: string, model: T): Promise<T> {
        return this.api.put<T>(`${FacetFieldConfigurations.baseUrl}/${id}`, model);
    }

    delete<T extends BaseFacetFieldConfigurationModel>(id: string) {
        return this.api.delete<T>(`${FacetFieldConfigurations.baseUrl}/${id}`);
    }
}
